'use strict';

import CartTileCore from 'org/components/product/CartTile';
var promotionSummary = require('../../checkout/promotionSummary');
var scrollAnimate = require('org/components/scrollAnimate');
var isVisible = require('org/components/isVisible');
export default class CartTile extends CartTileCore {
    /**
     * re-renders the order totals and the number of items in the cart
     * @param {Object} data - AJAX response from the server
     */
    updateCartTotals(data) {
        super.updateCartTotals(data);

        if (data.totals.totalDiscount.value > 0) {
            $('.order-discount.order-summary_discount').removeClass('hide-order-discount');
            $('.order-discount.order-summary_discount').show();
            $('.order-summary_discount .order-discount-total').empty()
                .append('- ' + data.totals.totalDiscount.formatted);
        } else {
            $('.order-discount.order-summary_discount').addClass('hide-order-discount');
        }

        // update subtotal for APAC in order summary
        $('.order-summary_items .sub-total').empty().append(data.totals.subTotalWithoutAdjustments);

        promotionSummary.updatePromotionInformation(data);

        data.items.forEach(function (item) {
            var itemPriceContainer = $('.uuid-' + item.UUID + ' .b-cartlineitem_price');
            itemPriceContainer.each(function (index) {
                var cartMemberPrice = $(itemPriceContainer[index]).find('.cart-member-price');
                cartMemberPrice.empty();
                if (
                    item.memberPricing.hasMemberPrice
                    && item.memberPricing.memberPromoApplied
                ) {
                    cartMemberPrice.append(item.memberPricing.pricing.badgeHtml);
                }
            });

            if (item.uaRewards) {
                const $lineItemDiv = $('.card-product-info.uuid-' + item.UUID);
                $lineItemDiv.attr('data-prorated-price', item.uaRewards.proratedPrice);
                $lineItemDiv.attr('data-categories', item.uaRewards.categories);
            }
        });
        $('body').trigger('uarewards:calculatePoints');
    }

    // eslint-disable-next-line class-methods-use-this
    updateLowInventoryMessaging(isLow, uuid) {
        var msgBlock = $('.cart-lowInventory-' + uuid);
        msgBlock.toggleClass('hide', !isLow);
    }

    onCouponRemove(event) {
        event.preventDefault();
        var url = this.$el.find(this.selectors.cartRemoveCouponButton).data('action');
        var uuid = this.$el.find(this.selectors.cartRemoveCouponButton).data('uuid'); // eslint-disable-line
        var couponCode = this.$el.find(this.selectors.cartRemoveCouponButton).data('code'); // eslint-disable-line
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = this.onAppendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            context: this,
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                // eslint-disable-next-line spellcheck/spell-checker
                $('.idme-content').empty().append(data.totals.idmePromosHtml);
                this.updateCartTotals(data);
                this.updateApproachingDiscounts(data.approachingDiscounts);
                this.validateBasket(data);
                $('body').removeClass('modal-open');
                $('body').trigger('cart:couponRemoved', {
                    basket: data
                });
                $.spinner().stop();
                if ($('#checkout-main').attr('data-checkout-stage') === 'shipping' || $('#checkout-main').attr('data-checkout-stage') === 'payment') {
                    window.location.reload();
                } else if ($('#checkout-main').attr('data-checkout-stage') === 'placeOrder') {
                    window.location.href = data.checkouturl;
                }
                window.location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    this.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    }

    onPromoSubmitResponse(event) {
        event.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.js-promo-code-form .form-control').addClass('m-error');
            $('.js-promo-code-form .form-control').closest('.b-input_row').addClass('m-error');
            $('.js-promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $('.coupon-missing-error').addClass('b-input_row-error_message');
            $.spinner().stop();
            return false;
        }
        var $form = $('.js-promo-code-form');
        $('.js-promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('.js-promo-code-form .form-control').removeClass('m-error');
        $('.js-promo-code-form .form-control').closest('.b-input_row').removeClass('m-error');

        var couponCode = $form.find('[name=couponCode]').val();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            context: this,
            success: function (data) {
                if (data.error) {
                    $('.js-promo-code-form .form-control').addClass('is-invalid');
                    $('.js-promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('.coupon-error-message').addClass('b-input_row-error_message');
                    $('body').trigger('cart:afterPromoAttempt', {
                        analytics: data && data.analytics,
                        couponCode: couponCode,
                        errorResponse: data
                    });
                    $('.coupon-code-field').val('');
                    $.spinner().stop();
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    // eslint-disable-next-line spellcheck/spell-checker
                    $('.idme-content').empty().append(data.totals.idmePromosHtml);
                    this.updateCartTotals(data);
                    this.updateApproachingDiscounts(data.approachingDiscounts);
                    this.validateBasket(data);
                    $('body').trigger('cart:afterPromoAttempt', {
                        basket: data,
                        analytics: data && data.analytics
                    });
                    if ($('#checkout-main').attr('data-checkout-stage') === 'shipping' || $('#checkout-main').attr('data-checkout-stage') === 'payment') {
                        window.location.reload();
                    } else if ($('#checkout-main').attr('data-checkout-stage') === 'placeOrder') {
                        window.location.href = data.checkouturl;
                    }
                    $('.coupon-code-field').val('');
                    $.spinner().stop();
                    window.location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    this.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    }

    updateProductDetails(data, uuid) {
        var lineItem = this.findItem(data.cartModel.items, function (item) {
            return item.UUID === uuid;
        });

        var skuSelector = $('.card.card-product-info.uuid-' + uuid).find('.product-sku');
        var skuText = '';
        if (skuSelector && skuSelector.text() && skuSelector.text().split('-').length > 0) {
            skuText = skuSelector.text().split('-')[0];
        } else {
            skuText = lineItem.id;
        }

        if (lineItem.variationAttributes) {
            var colorAttr = this.findItem(lineItem.variationAttributes, function (attr) {
                return attr.attributeId === 'color';
            });

            if (colorAttr) {
                var colorSelector = '.' + colorAttr.displayName + '-' + uuid;
                var displayColorWay = '<span>';
                var colorWay = lineItem.custom.colorway;
                if (colorWay) {
                    let colorBuckets = colorWay.split('/').map(function (item) {
                        return item.trim();
                    });
                    if (colorBuckets.length > 1) {
                        displayColorWay += colorBuckets[0];
                        if (colorBuckets[1] !== '' && colorBuckets[0] !== colorBuckets[1]) {
                            displayColorWay += ' / ' + colorBuckets[1];
                        } else if (colorBuckets[2] && colorBuckets[2] !== '' && colorBuckets[2] !== colorBuckets[1]) {
                            displayColorWay += ' / ' + colorBuckets[2];
                        }
                        displayColorWay += '</span>';
                    } else {
                        displayColorWay += colorWay + '</span>';
                    }
                    displayColorWay += '<span> - ' + lineItem.custom.color + '</span>';
                } else {
                    displayColorWay = '<span>' + colorAttr.displayValue + '</span><span> - ' + lineItem.custom.color + '</span>';
                }
                $(colorSelector).html(colorAttr.displayName + ': ' + displayColorWay);
                skuText += '-' + lineItem.custom.color;
            }

            var sizeAttr = this.findItem(lineItem.variationAttributes, function (attr) {
                return attr.attributeId === 'size';
            });

            if (sizeAttr) {
                var sizeSelector = '.' + sizeAttr.displayName + '-' + uuid;
                var newSize = sizeAttr.displayName + ': ' + sizeAttr.displayValue;
                $(sizeSelector).text(newSize);
                skuText += (lineItem.custom && lineItem.custom.size) ? '-' + lineItem.custom.size : '-' + sizeAttr.displayValue;
            }

            var imageSelector = `.card.card-product-info.uuid-${uuid} .line-item-image .line-item-product-image`;
            $(imageSelector).attr('src', lineItem.images.cartFullDesktop[0].url);
            $(imageSelector).attr('alt', lineItem.images.cartFullDesktop[0].alt);
            $(imageSelector).attr('title', lineItem.images.cartFullDesktop[0].title);
        }

        skuSelector.text(skuText);

        if ($('.uuid-' + uuid).hasClass('egiftcardlineitem') && lineItem.custom.gcRecipientName !== null && lineItem.custom.gcRecipientEmail !== null && lineItem.custom.gcFrom !== null && lineItem.custom.gcDeliveryDate !== null) {
            var egiftCardLineitem = $('.uuid-' + uuid + '.egiftcardlineitem');
            $(egiftCardLineitem).find('.gcrecipientname').children('.egiftcard-value').text(lineItem.custom.gcRecipientName);
            $(egiftCardLineitem).find('.gcrecipientemail').children('.egiftcard-value').text(lineItem.custom.gcRecipientEmail);
            $(egiftCardLineitem).find('.gcfrom').children('.egiftcard-value').text(lineItem.custom.gcFrom);
            $(egiftCardLineitem).find('.gcdeliverydate').children('.egiftcard-value').text(new Date(Date.parse(lineItem.custom.gcDeliveryDate)).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }));
        }

        var qtySelector = `.js-quantity-select[data-uuid="${uuid}"]`;
        if (lineItem.quantity > 10) {
            $(qtySelector).empty();
            for (var optionNum = 0; optionNum < lineItem.quantity; optionNum++) {
                $(qtySelector).append('<option>' + (optionNum + 1) + '</option>');
            }
        }
        $(qtySelector).val(lineItem.quantity);
        $(qtySelector).data('pid', data.newProductId);

        var $updatedSpanQuantity = $('.js-quantity-' + uuid);
        $updatedSpanQuantity.empty().html(lineItem.quantity);
        if (lineItem.quantity > 1) {
            $('.b-cartlineitem_quantity-update').removeClass('hide');
        }

        $(`.remove-product-item[data-uuid="${uuid}"]`).attr('data-pid', data.newProductId);
        $(`.edit-link.js-save-later[data-uuid="${uuid}"]`).attr('data-pid', data.newProductId);
        $(`.card.card-product-info.uuid-${uuid}`).attr('data-pid', data.newProductId);

        var priceSelector = `.line-item-price-${uuid} .sales .value`;
        $(priceSelector).text(lineItem.price.sales.formatted);
        $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

        if (lineItem.price.list) {
            var listPriceSelector = `.line-item-price-${uuid} .list .value`;
            $(listPriceSelector).text(lineItem.price.list.formatted);
            $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
        }
        // update OOS
        var currentProductDiv = $(`.b-cartlineitem.card-product-info.card.uuid-${uuid}`);
        if (lineItem.availabilityError && !lineItem.isPartiallyAvailable && !currentProductDiv.hasClass('b-cartlineitem_outofstock')) {
            currentProductDiv.addClass('b-cartlineitem_outofstock');
        } else if (currentProductDiv.hasClass('b-cartlineitem_outofstock')) {
            currentProductDiv.removeClass('b-cartlineitem_outofstock');
        }

        // update personlization
        currentProductDiv.find('div.personalize-detail').html('');
        if (lineItem.personalizationDetail && currentProductDiv.find('div.personalize-detail').length > 0) currentProductDiv.find('div.personalize-detail').html(lineItem.personalizationDetail);
        var optionName = lineItem.options ? lineItem.options.find(option => option.optionId === 'personalizations') : null;
        var displayName = currentProductDiv.find('div[data-option-id="personalizations"]').find('p.t-lineitem_attributes');
        if (optionName && displayName.length > 0) displayName.html(optionName.displayName);
    }

    onUpdateCartProduct(event) {
        // super.onUpdateCartProduct(event);
        event.preventDefault();

        var $target = $(event.target);

        var updateProductUrl = $target.closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $target.closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var quantitySelector = $('.b-product-quickview-qty .js-quantity-select');
        selectedQuantity = quantitySelector.length > 0 && quantitySelector.find('option').length > selectedQuantity ? selectedQuantity : quantitySelector.val();
        var uuid = $target.closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: this.getPidValue($target),
            quantity: selectedQuantity
        };

        if ($('input[type="hidden"][name="personalizationName"]').length > 0) form.personalizationName = $('input[name="personalizationName"]').val();
        if ($('input[type="hidden"][name="personalizationNumber"]').length > 0) form.personalizationNumber = $('input[name="personalizationNumber"]').val();
        if ($('input[type="hidden"][name="personalizationSponsors"]').length > 0) form.personalizationSponsors = $('input[name="personalizationSponsors"]').val();

        form.selectedOptionValueId = $('div[data-option-id="personalizations"]').find('option:selected').data('value-id');

        if ($(this.selectors.eGiftCardForm).length > 0) {
            var eGiftCardData = $(this.selectors.eGiftCardForm).serializeArray();
            var eGiftCardFormData = {};
            eGiftCardData.forEach(function (data) {
                eGiftCardFormData[data.name] = data.value;
            });
            form.eGiftCardData = JSON.stringify(eGiftCardFormData);
        }

        $target.parents('.card').spinner().start();
        var attrsContainer = $('.b-product_attrs');
        if (updateProductUrl) {
            var requiredSelections = ['color', 'length', 'size', 'amount'];
            var missingSelection = [];
            $.each(requiredSelections, function (index, attributeKey) {
                var attribute = attrsContainer.find('.b-product_attrs-item[data-attr="' + attributeKey + '"]') && attrsContainer.find('.b-product_attrs-item[data-attr="' + attributeKey + '"]').length ? attrsContainer.find('.b-product_attrs-item[data-attr="' + attributeKey + '"]') : attrsContainer.find('.b-product_qvattrs-item[data-attr="' + attributeKey + '"]');
                if (attribute.length) {
                    if (attribute.find('.m-active').length === 0 && attribute.find('.selected').length === 0) {
                        missingSelection.push(attributeKey);
                        attribute.addClass('has-error');
                        if (attribute.find('.invalid-feedback').length) {
                            attribute.find('.invalid-feedback').show();
                        } else {
                            attribute.append('<div class="invalid-feedback">' + attribute.data('error-label') + '</div>');
                        }
                        $('body').trigger('addtobag:error', { errorMsg: attribute.data('error-label') });
                        if (!isVisible(attribute)) {
                            scrollAnimate(attribute);
                        }
                    } else if (attribute.find('.m-active.disabled').length && attribute.find('.selected.disabled').length) {
                        missingSelection.push(attributeKey);
                        var oosMsg = attribute.find('.m-' + attribute.data('attr')).data('error-message-label');
                        if (attribute.find('.selection-error-message').length) {
                            attribute.find('.selection-error-message').show();
                        } else {
                            attribute.append('<div class="selection-error-message">' + oosMsg + '</div>');
                        }
                        $('body').trigger('addtobag:error', {
                            errorMsg: oosMsg
                        });
                    } else {
                        attribute.removeClass('has-error');
                        attribute.find('.invalid-feedback').hide();
                        attribute.find('.selection-error-message').hide();
                    }
                }
            });
            if (missingSelection.length === 0) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $target.closest('.g-modal').modal('hide');
                        if ($('.b-cart-bopis_shipping').length > 0 || $('.b-cart-pickup-heading').length > 0) {
                            window.location.reload();
                        } else {
                            var lineItem = this.findItem(data.cartModel.items, function (item) {
                                return item.UUID === uuid;
                            });
                            var $updatedQuantity = $('.js-quantity-' + uuid);
                            if ('quantities' in lineItem && lineItem.quantities.quantities !== null) {
                                this.updateQuantities(lineItem.quantities, uuid);
                            }
                            $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                            $updatedQuantity.empty().html(selectedQuantity);
                            $updatedQuantity.parent().addClass('hide');
                            if (selectedQuantity > 1) {
                                $updatedQuantity.parent().removeClass('hide');
                            }
                            this.updateLowInventoryMessaging(lineItem.hasLowInventory, uuid);
                            this.updateCartTotals(data.cartModel);
                            this.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                            this.updateAvailability(data.cartModel, uuid);
                            this.updateProductDetails(data, uuid);
                            if (data.uuidToBeDeleted) {
                                $('.uuid-' + data.uuidToBeDeleted).remove();
                            }
                            this.validateBasket(data.cartModel);
                            $('body').trigger('cart:lineItemEdited', {
                                basket: data.cartModel
                            });
                            $('body').trigger('cart:update');
                            if (lineItem && Object.hasOwnProperty.call(lineItem.custom, 'masterQtyLimit') && lineItem.custom.masterQtyLimit) {
                                $('#quantity-' + uuid).attr('disabled', true);
                            }
                            $.spinner().stop();
                        }
                    }.bind(this),
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            if (err.responseJSON.messages && !err.responseJSON.masterQtyLimitError) {
                                var availabilityMessages = [];
                                var availabilityValue = '';
                                availabilityMessages = err.responseJSON.messages;
                                if (availabilityMessages.length === 2) {
                                    availabilityValue = '<div class="b-product-quickview-stock_icon"></div><div class="b-product-quickview-stock_text">';
                                }
                                availabilityMessages.forEach((message) => {
                                    availabilityValue += `<div>${message}</div>`;
                                });
                                if (availabilityMessages.length === 2) {
                                    availabilityValue += '</div>';
                                }
                                $('.b-product-quickview-stock_Message').removeClass('hide');
                                $('.b-product-quickview-stock_Message').empty().html(availabilityValue);
                            } else if (err.responseJSON.masterQtyLimitError) {
                                var masterQtyLimitErrorMessage = '';
                                masterQtyLimitErrorMessage = '<div class="selection-error-message"><div>' + err.responseJSON.errorMessage + '</div></div>';
                                $('.selection-error-message').removeClass('hide');
                                $('.selection-error-message').empty().html(masterQtyLimitErrorMessage);
                            } else if (err.responseJSON.invalidGiftCardAmount) {
                                var amountField = $('.js-giftcard-amount');
                                amountField.addClass('is-invalid');
                                amountField.parent('.b-input_row').addClass('error-field');
                                amountField.parent().find('.invalid-gcamount-feedback').html(amountField.data('valid-error'));
                                amountField.parents('.b-product_attrs-item').addClass('has-error');
                                amountField[0].setCustomValidity(amountField.data('valid-error'));
                            } else {
                                this.createErrorNotification(err.responseJSON.errorMessage, $target);
                            }
                            $.spinner().stop();
                        }
                    }
                });
            } else {
                $.spinner().stop();
            }
        }
    }
}
